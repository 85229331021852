export enum EInventoryCondition {
  'REJECT' = 'REJECT',
  'PRIME' = 'PRIME'
}

export type InventoryStatusCondition =
  | null
  | {
      id: 2;
      value: 'REJECT';
    }
  | {
      id: 1;
      value: 'PRIME';
    };

export type InventoryStatusType =
  | {
      id: 1;
      value: 'Active';
    }
  | {
      id: 2;
      value: 'Inactive';
    }
  | {
      id: 3;
      value: 'Deleted';
    }
  | {
      id: 4;
      value: 'System';
    };

export interface IInventoryStatus {
  id: number;
  value: 'string';
  blocking: boolean;
  status: InventoryStatusType;
  inventoryStatusType: InventoryStatusCondition;
}
